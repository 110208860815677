@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


:root {
  --orange: #ff8200;
  --gunmetal: #2a2d34;
  --seaGreen: #5c946e;
  --nonPhotoBlue: #a0dde6;

}

.App {
  text-align: left;
  font-family: 'Bebas Neue', sans-serif;

}

.Intro {
  background-color: var(--orange);
  color: var(--gunmetal);
  padding: 5%;
}

.Faq {
  background-color: var(--seaGreen);
  color: var(--gunmetal);
  padding: 5%;
  width: 90%;
}

.SignupForm {
  background-color: var(--nonPhotoBlue);
  color: var(--gunmetal);
  width: 90%;
  padding: 5%;
}

.ImageSpacer{
  background-color: var(--orange);
  width: 100%;
}
.ImageSpacer img{
  width: 100%;
  display: block;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiSnackbar-anchorOriginBottomRight{
  background-color: var(--orange);
  padding: 1em;
  border-style: solid;
  border-width: thin;
  border-color: var(--gunmetal);
}


.TopBanner{
  background-color: var(--orange);
  width: 100%;
}
.TopBanner img{
  width: 80%;
}

.box {
  width: 80%; height: auto;
  position: relative;
  text-align: center;
  background: var(--orange);
  margin:auto;
  
  font-weight: 900;
  font-style: normal;
  color: var(--gunmetal);
  font-family: 'Bebas Neue', sans-serif;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
